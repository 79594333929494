<template>
  <RouterView />

  <BaseToolbar>
    <div class="flex jc-sb h-100">
      <!-- Filter status -->
      <div class="flex ai-c ggap-10">
        <FormSelect
          label=""
          placeholder="Не группировать"
          class="sm"
          :options="optionsGroup"
          required
          v-model="tableGrouped"
        />
        <TableSettings storeName="orders" />
        <button class="table-settings__btn btn sm transparent-primary" type="button" @click="toggleExcelAction">
          <BaseIcon class="ic-16 primary" icon="doc-2" />
          Скачать отчет
        </button>
        <button
          class="table-settings__btn btn sm"
          :class="{ primary: archive, 'transparent-primary': !archive }"
          type="button"
          v-tippy="'Архив содержит завершенные и отклоненные заявки'"
          @click="archiveApplications"
        >
          <BaseIcon class="ic-16" icon="doc-1" :class="{ white: archive, primary: !archive }" />
          {{ archive ? 'Скрыть архивные' : 'Архив' }}
        </button>
      </div>

      <!-- Settings -->
      <div class="flex ai-c ggap-10">
        <template v-if="countFilters">
          <span class="divider" />
          <button class="btn sm transparent-primary pl-10 pr-10" @click="clearFilters">
            <BaseIcon class="ic-16 red" icon="filter" />
            Сбросить фильтры
            <small class="marker primary">
              {{ countFilters }}
            </small>
          </button>
        </template>
      </div>
    </div>
  </BaseToolbar>

  <div class="content pos-r z1 overflow-a default-mains">
    <BaseLoad v-if="isLoad" class="grid jc-c z99999 bg" />
    <TableWrapper class="max-content" :cols="cols" :rows="rows" :isFoot="false" />

    <TableNative
      :cols="cols"
      :rows="rows"
      :isOpenAll="isOpenAll"
      :visited="visitedId"
      :groupedRows="groupedRows"
      :isGrouped="!!tableGrouped"
      @toggleAll="toggleAll"
    >
      <!-- thead -->
      <template #th="{ col }">
        <div class="flex ai-c ggap-5">
          <!-- Filter -->
          <Filters v-if="col.filter" :col="col" @getData="getRows" />

          <!-- Sort -->
          <button
            v-if="col.sort"
            class="btn transparent-grey xsm pl-5 pr-5"
            :class="{
              active: tableSort.key === col.key,
              rotate: tableSort.key === col.key && tableSort.direction === 'asc'
            }"
            type="button"
            v-tippy="'Сортировка'"
            @click="setSort(col.key)"
          >
            <BaseIcon class="ic-14 grey" icon="sort" />
          </button>
        </div>
      </template>

      <!-- tbody -->
      <template #td="{ col, row }">
        <Cell :col="col" :row="row" />
      </template>
    </TableNative>
    <BaseLoad class="pos-s" v-if="isLoadMore" />
  </div>

  <div v-if="excelOpen" class="col-filter__body box grid" :data-body="uniqId" v-click-outside="toggleExcelAction">
    <BaseLoad v-if="isLoad" class="white z2" />
    <div class="flex ai-c ggap-5 p-10">
      <span>Выберите период:</span>
    </div>
    <hr class="m-0" />
    <!-- Body -->
    <div class="grid ggap-10 p-10">
      <FormDate
        showPastDays
        v-model="excelForm.start"
        type="date"
        required
        format="YYYY-MM-DD"
        label="Дата с"
        placeholder="-"
      />
      <FormDate
        :startDate="startDate"
        v-model="excelForm.end"
        type="date"
        required
        format="YYYY-MM-DD"
        label="Дата до"
        placeholder="-"
      />
    </div>
    <hr class="m-0" />
    <button class="btn grey m-10" :disabled="!(excelForm.start && excelForm.end)" @click="downloadExcelHandler()">
      Скачать
    </button>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, ref, watchEffect, onMounted } from 'vue'
import { BaseIcon, BaseLoad, BaseToolbar, TableNative, FormSelect, FormDate } from '@/components'
import TableSettings from './components/table-settings/Index.vue'
import Cell from './components/table/Cell.vue'
import Filters from './components/table/Filters.vue'
import socketConnect from '@/plugins/socket-connect'
import { calcCoords, $busEmit, $busOn } from '@/plugins'
import orders from '@/api/modules/orders'

// Data
const store = useStore()
const requestCount = ref(0)
const isLoad = ref(false)
const isLoadMore = ref(false)
const isOpenAll = ref(true)
const filterStatus = ref('')
const groupedRows = ref([])
const excelOpen = ref(false)
const excelForm = ref({ start: '', end: '' })
const uniqId = 'table-settings'
const archiveLocal = JSON.parse(localStorage.getItem('archiveShow')) ?? false
const archive = ref(archiveLocal)

const optionsGroup = [
  {
    text: 'Группировать по исполнителю',
    value: 'responsible'
  },
  {
    text: 'Группировать по инициатору',
    value: 'author'
  },
  {
    text: 'Группировать по контрагенту',
    value: 'counterparty'
  },
  {
    text: 'Группировать по проекту',
    value: 'project'
  },
  {
    text: 'Группировать по статусу',
    value: 'status'
  }
]

// Created
getRows()
if (localStorage.getItem('choco_grouped')) {
  store.commit('orders/SET_TABLE_GROUPED_BY', localStorage.getItem('choco_grouped'))
}
onMounted(() => {
  const el = document.querySelector('.default-mains')
  el.addEventListener('scroll', async function () {
    if (el.scrollTop > el.scrollHeight - 1000) await getNextRows()
  })
})
wsConntect()

// Computed
const page = computed(() => store.getters['orders/getPage'])
const tableSort = computed(() => store.getters['orders/tableSort'])
const pageCount = computed(() => store.getters['orders/getPageCount'])
const tableGrouped = computed({
  get: () => store.getters['orders/tableGrouped'],
  set: (val) => store.commit('orders/SET_TABLE_GROUPED_BY', val)
})
const colsStore = computed(() => store.getters['orders/tableCols'])
const cols = computed(() => {
  // Убираем колонку по которой группируют
  return colsStore.value.map((item) => {
    if (tableGrouped.value && item.key === tableGrouped.value) {
      item.disabled = true
    } else {
      item.disabled = false
    }
    return item
  })
})
const visitedId = computed(() => store.getters['orders/item']?.id || 0)
const filters = computed(() => store.getters['orders/filters'])
const rows = computed(() => {
  const res = store.getters['orders/rows']?.length ? store.getters['orders/rows'] : []
  return filterStatus.value ? res.filter((item) => item.status.uid === filterStatus.value) : res
})

// Computed:counters
const countFilters = computed(() => Object.keys(store.getters['orders/filters']).length)

// Watch
watchEffect(() => {
  if (tableGrouped.value && rows.value?.length) groupedBy()
})

$busOn('archive', () => {
  clearFilters()
})

// Methods
function archiveApplications() {
  localStorage.setItem('archiveShow', !archive.value)
  $busEmit('archive', !archive.value)
  archive.value = !archive.value
}

function clearFilters() {
  store.commit('orders/SET_PAGE', 1)
  const el = document.querySelector('.default-mains')
  el.scrollTop = 0
  store.commit('orders/CLEAR_FILTERS')
  getRows()
}

function setSort(key) {
  store.commit('orders/SET_SORT', key)
  getRows()
}

function getRows() {
  isLoad.value = true
  store.dispatch('orders/GET_ROWS').finally(() => {
    requestCount.value = requestCount.value + 1
    isLoad.value = false
  })
}

function getNextRows() {
  if (page.value < pageCount.value && !isLoadMore.value) {
    isLoadMore.value = true
    store.dispatch('orders/GET_MORE_ROWS').finally(() => {
      isLoadMore.value = false
    })
  }
}

function getUniqArray(items) {
  const uniqArray = []
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    const index = uniqArray.findIndex((el) => el?.id === item[tableGrouped.value]?.id)
    if (index === -1) uniqArray.push(item[tableGrouped.value])
  }
  return uniqArray
}

function toggleExcelAction() {
  excelOpen.value = !excelOpen.value
  calcCoords(uniqId, 'ltr')
}

function groupedBy() {
  isOpenAll.value = true
  groupedRows.value.splice(0)

  // Собираем все уникальные ключи
  const uniqItems = rows.value?.length ? getUniqArray(rows.value) : []

  // Формируем массив
  for (let uniqItem of uniqItems) {
    if (uniqItem?.id) {
      groupedRows.value.push({
        id: uniqItem?.id || 0,
        groupedItem: uniqItem,
        groupedKey: tableGrouped.value,
        groupedValue: uniqItem?.name || uniqItem?.fullname || uniqItem?.project || '',
        opened: true,
        subrows: rows.value.filter((row) => row[tableGrouped.value]?.id === uniqItem?.id)
      })
    } else {
      groupedRows.value.unshift({
        id: 0,
        groupedItem: uniqItem,
        groupedKey: tableGrouped.value,
        groupedValue: '',
        opened: true,
        subrows: rows.value.filter((row) => row[tableGrouped.value]?.id === uniqItem?.id)
      })
    }
  }
}
function toggleAll() {
  isOpenAll.value = !isOpenAll.value
  groupedRows.value.forEach((item) => (item.opened = isOpenAll.value))
}

async function downloadExcelHandler() {
  const f = filters.value
  const start = excelForm.value.start
  const end = excelForm.value.end
  const size = 50 * page.value
  try {
    const { data } = await orders.downloadExcel(f, size, 1, start, end)
    var a = document.createElement('a')
    a.href = 'data:image/png;base64,' + data
    a.download = `Заявки с ${start} по ${end}.xlsx`
    a.click()
    excelForm.value = { start: '', end: '' }
    excelOpen.value = false
  } catch (err) {
    console.log(err)
    $busEmit('setToast', {
      type: 'red',
      icon: 'alert-triangle',
      message: 'Произошла ошибка при формировании отчета!'
    })
  }
}

// Methods:websocket
function wsConntect() {
  socketConnect.channel('request_created').listen('.requestCreatedEvent', () => {
    getRows()
  })
}
</script>
