<template>
  <!-- Status -->
  <template v-if="col.key === 'status'">
    <div class="igrid cut">
      <small
        v-if="row[col.key]"
        class="marker cut"
        :style="{ color: row[col.key].text_color, backgroundColor: row[col.key].background_color }"
      >
        {{ row[col.key].name }}
      </small>
      <template v-else>-</template>
    </div>
  </template>

  <!-- Documents -->
  <template v-else-if="col.key === 'documents'">
    <div class="flex ai-c ggap-5">
      <BaseIcon class="ic-16" :class="row[col.key] && row[col.key].length ? 'primary' : 'grey'" icon="file" />
      <span>{{ (row[col.key] && row[col.key].length) || 0 }}</span>
    </div>
  </template>

  <!-- Deadline -->
  <template v-else-if="col.key === 'deadline'">
    <div class="igrid cut">
      <div v-if="row[col.key]" class="flex ai-c ggap-5">
        <BaseIcon class="ic-16" :class="getDayCount(row[col.key]) !== 0 ? 'primary' : 'red'" icon="calendar" />
        <span class="cut" :class="getDayCount(row[col.key]) !== 0 ? '' : 't-red'">
          {{ getDayCount(row[col.key]) === 0 ? 'Истёк' : getDayCount(row[col.key]) }}
        </span>
      </div>
      <template v-else>-</template>
    </div>
  </template>

  <!-- Date in work -->
  <template v-else-if="col.key === 'got_for_work_at'">
    <div v-if="row[col.key]" class="igrid cut gtc-auto-1 ai-c ggap-5">
      <BaseIcon class="ic-16 primary" icon="calendar" />
      <span class="cut">
        {{ formatRuDateTime(row[col.key]) }}
      </span>
    </div>
    <template v-else>-</template>
  </template>

  <!-- Price -->
  <template v-else-if="col.key === 'price'">
    <div class="igrid cut">
      <span v-if="row[col.key]" class="cut t-upper">
        {{ row[col.key].toLocaleString('ru-RU') }} {{ row.currency || 'kzt' }}
      </span>
      <template v-else>-</template>
    </div>
  </template>

  <!-- Type -->
  <template v-else-if="col.key === 'type'">
    <div class="igrid cut">
      <span class="cut">
        {{ row[col.key] && row[col.key] && optionsOrderType?.find((el) => el.value === row[col.key])?.text }}
      </span>
    </div>
  </template>

  <!-- Author -->
  <template v-else-if="col.key === 'author'">
    <div class="igrid cut">
      <small v-if="isAuthor" class="marker primary cut"> Вы </small>
      <span v-else class="cut">
        {{ row[col.key] && row[col.key].fullname ? cutFullName(row[col.key].fullname) : '-' }}
      </span>
    </div>
  </template>

  <!-- Responsible -->
  <template v-else-if="col.key === 'responsible'">
    <div class="igrid cut">
      <small v-if="isResponsible" class="marker primary cut"> Вы </small>
      <span v-else class="cut">
        {{ row[col.key] && row[col.key].fullname ? cutFullName(row[col.key].fullname) : '-' }}
      </span>
    </div>
  </template>

  <!-- Project -->
  <template v-else-if="col.key === 'project'">
    <div class="igrid cut">
      <span class="cut">
        {{ row[col.key] && row[col.key].project ? row[col.key].project : '-' }}
      </span>
    </div>
  </template>

  <!-- Counterparty -->
  <template v-else-if="col.key === 'counterparty'">
    <div class="igrid cut">
      <span class="cut">
        {{ row.counterparty ? row.counterparty_name : '-' }}
      </span>
    </div>
  </template>

  <!-- Created && Updated -->
  <template v-else-if="col.key === 'created_at' || col.key === 'updated'">
    <div class="igrid cut">
      <span class="cut">
        {{ row[col.key] ? formatRuDateTime(row[col.key]) : '-' }}
      </span>
    </div>
  </template>

  <!-- My Role -->
  <template v-else-if="col.key === 'myrole'">
    <div v-if="myRoles.length" class="flex ai-c ggap-5 fs-16">
      <span v-for="role of myRoles" :key="role.key" v-tippy="role.name">
        {{ role.icon }}
      </span>
    </div>
    <template v-else>-</template>
  </template>

  <!-- Comments -->
  <template v-else-if="col.key === 'comments'">
    <div class="igrid cut">
      <span class="cut">
        {{ row[col.key] ? cleanedComments : '-' }}
      </span>
    </div>
  </template>

  <!-- Other -->
  <template v-else>
    <div class="igrid cut">
      <span class="cut">
        <!-- {{ col.formatter ? col.formatter(row[col.key]) : row[col.key] ? row[col.key] : '-' }} -->
        {{ row[col.key] ? row[col.key] : '-' }}
      </span>
    </div>
  </template>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, defineProps, toRefs } from 'vue'
import { BaseIcon } from '@/components'
import { cutFullName, formatRuDateTime, getDayCount } from '@/plugins'

// Props
const props = defineProps({
  col: {
    type: Object,
    default: () => ({})
  },
  row: {
    type: Object,
    default: () => ({})
  }
})

// Data
const { col, row } = toRefs(props)
const store = useStore()
const optionsOrderType = [
  {
    text: 'Создать документ',
    value: 'create'
  },
  {
    text: 'Согласовать документ',
    value: 'matching'
  },
  {
    text: 'Согласование МС',
    value: 'mc_agreement'
  }
]

// Computed
const cleanedComments = computed(() => row.value[col.value.key].replace(/<\/?[^>]+(>|$)/g, ''))
const profile = computed(() => store.getters.profile)
const isAuthor = computed(() => row.value?.author?.id === profile.value.id)
const isResponsible = computed(() => row.value?.responsible?.id === profile.value.id)
const myRoles = computed(() => {
  const set = new Set()
  const res = []
  const myID = profile.value.id

  // Review - free
  if (row.value?.reviewers?.length) {
    for (let i = 0; i < row.value.reviewers.length; i++) {
      const element = row.value.reviewers[i]
      if (element.id === myID) set.add('review')
    }
  }

  // Review - depart
  else if (row.value?.review_departments?.length) {
    for (let i = 0; i < row.value.review_departments.length; i++) {
      const element = row.value.review_departments[i]
      const users = element.users.map((el) => el.id)
      const manual_users = element.manual_users.map((el) => el.id)
      const allUsers = [...users, ...manual_users]

      if (allUsers.includes(myID)) set.add('review')
    }
  }

  // Agree - free
  if (row.value?.agreementers?.length) {
    for (let i = 0; i < row.value.agreementers.length; i++) {
      const element = row.value.agreementers[i]
      if (element.id === myID) set.add('agree')
    }
  }

  // Agree - depart
  else if (row.value?.agreement_departments?.length) {
    for (let i = 0; i < row.value.agreement_departments.length; i++) {
      const element = row.value.agreement_departments[i]
      const users = element.users.map((el) => el.id)
      const manual_users = element.manual_users.map((el) => el.id)
      const allUsers = [...users, ...manual_users]

      if (allUsers.includes(myID)) set.add('agree')
    }
  }

  // Watch - free
  if (row.value?.watchers?.length) {
    for (let i = 0; i < row.value.watchers.length; i++) {
      const element = row.value.watchers[i]
      if (element.id === myID) set.add('watch')
    }
  }

  // Watch - depart
  else if (row.value?.watcher_departments?.length) {
    for (let i = 0; i < row.value.watcher_departments.length; i++) {
      const element = row.value.watcher_departments[i]
      const users = element.users.map((el) => el.id)
      const manual_users = element.manual_users.map((el) => el.id)
      const allUsers = [...users, ...manual_users]

      if (allUsers.includes(myID)) set.add('watch')
    }
  }

  // Preparing data
  for (const item of set) {
    res.push({
      key: item,
      name: item === 'review' ? 'Рассматривающий' : item === 'agree' ? 'Согласующий' : 'Подглядывающий',
      icon: item === 'review' ? '💬' : item === 'agree' ? '👍' : '👀'
    })
  }

  // Final boss
  if (row.value?.final_boss?.id === myID) {
    res.push({
      key: 'final-boss',
      name: 'Финальный босс',
      icon: '✍'
    })
  }

  return res
})
</script>

<style></style>
