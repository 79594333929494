<template>
  <div class="col-item flex ai-c jc-sb" :class="{ disabled: !item.show }">
    <div class="col-item__name p-10 pl-20">{{ item.name }}</div>

    <div class="col-item__btns flex ai-c h-100">
      <button class="col-item__btns-item" @click="item.show = !item.show" v-if="!item.canHide">
        <BaseIcon class="ic-16" :class="item.show ? 'primary' : 'red'" :icon="item.show ? 'eye' : 'eye-off'" />
      </button>
      <button class="col-item__btns-item handle">
        <BaseIcon class="ic-16 black" icon="menu" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, toRefs, computed } from 'vue'
import { BaseIcon } from '@/components'

// Emits
const emits = defineEmits(['update:col'])

// Props
const props = defineProps(['col'])

// Data
const { col } = toRefs(props)

// Computed
const item = computed({
  get: () => col.value,
  set: (val) => emits('update:col', val)
})
</script>

<style lang="scss" scoped>
.col-item {
  opacity: 1 !important;
  border-bottom: var(--b1);

  &.ghost {
    color: transparent;
    background-color: var(--primary-el);
  }

  &.disabled {
    color: #ccc;
  }
  &:hover {
    background-color: var(--grey-l);
  }

  &:last-child {
    border: 0;
  }

  &__btns {
    &-item {
      border: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 50px;

      &:hover {
        background-color: var(--grey);
      }
      &:active {
        background-color: var(--grey-d);
      }
    }
  }

  &.ghost &__btns {
    opacity: 0;
  }
}
</style>
